.App {
  text-align: left;
  background-color: #1E1E1E;
  overflow:auto;
  height: 100dvi;
}

.App::before, .App::after {
  content: "";
  position: fixed;
  background-color: #BDBBBB; /* Color of the lines */
}

.App::before {
  width: 100%; /* Full width for the horizontal line */
  height: 3%; /* Thickness of the horizontal line */
  top: 50%; /* Center vertically */
  left: 0;
}

.App::after {
  height: 100%; /* Full height for the vertical line */
  width: 3%; /* Thickness of the vertical line */
  top: 0;
  left: 30%; /* Center horizontally */
}

.App-header, .Input-character, .Character-list {
  position: relative; /* Ensure content appears above the lines */
  z-index: 1; /* Higher z-index than pseudo-elements */
}



.App-header {
  padding-top: 2%;
  min-height: 25%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: center;
  position: relative;
  margin-left: 5%;
  font-size: calc(5px + 2vmin);
  color: 1E1E1E;
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 0.5px;
  font-family: 'Koulan', sans-serif;
  letter-spacing: 10px;
}

.Input-character{
  padding-left: 10%;
  color: #ffffff;
}
@font-face {
  font-family: 'Koulan';
  src: url(./fonts/koulan.ttf) format('truetype');
}

@font-face {
  font-family: 'jetbrainsmono';
  src: url(./fonts/jetbrainsmono.ttf) format('truetype');
}

h1 {
  line-height: 0.7; /* Adjust line spacing for h1 */
}

h2 {
  line-height: 0.7; /* Adjust line spacing for h2 */
}
h4{
  line-height: 0.7;
  font-size: calc(4px + 2vmin);
}

.dropdown {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  margin: 100px auto;
  position: relative;
  user-select:none;
  z-index: 2;
}



.dropdown .dropdown-button {
  /*border-radius: 10px 10px 0px 0px;*/
  padding: 15px 20px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  font-weight: bold;
  align-items: center;
  align-content: center;
  text-align: center;
  cursor: pointer;
  font-size: calc(5px + 1.5vmin);

  font-family: 'Koulan', sans-serif;
  justify-content: space-between;
}

.dropdown .dropdown-content {
  top: 100%;
  width: 88%;
  left: 6%;
  color: #333;
  position: absolute;
  max-height: 200px; /* Adjust based on your preference */
  overflow-y: auto; /* Enables vertical scrolling */
  font-weight: 500;
  font-family: 'Koulan', sans-serif;
}

.dropdown .dropdown-content .dropdown-item{
  background: #fcfcfc;
  cursor: pointer;
  transition: all 0.2s;
  font-family: 'Koulan', sans-serif;

}

.dropdown-content{
  z-index: 3;
}
.dropdown-item {
  z-index: 4;
}

.dropdown .dropdown-content .dropdown-item:hover{
  background: #f4f4f4;
}


.dropdownContainer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  bottom: 70px;
  height: 100vh; /* Full height of the viewport */
  flex-wrap:nowrap;
  width: 100%;
  padding: 10px;
}
.dropdown-button{
  text-align: center;
}



@media (max-width: 768px) {
  .dropdownContainer {
    flex-direction: column;
    display: flex;
    align-items:start;
    position: relative;
    justify-content:flex-start;
    gap: 10px;
    width: 100%;
    margin-top: 5%;
    padding: 0 10px;
    height: fit-content;
  }
  .dropdownContainer > div {
    width: 100%; /* Make each dropdown take up full width */
    height: fit-content;
    margin: 0; /* Remove any default margin */
  }
  .dropdownContainer .selectCharacter,
  .dropdownContainer .selectMove,
  .dropdownContainer .selectPunishingCharacter {
    width: 100%; /* Ensure each dropdown is the same width */
    margin-bottom: 0px; /* Space between dropdowns */
  }
  .dropdown .dropdown-content{
    position:static;
    width: 100%;
  }

  
  .selectCharacter{
    flex: 1;
    margin: 10px;
    text-align: center;
    padding: 20px;
  }
}

.selectCharacter{
  flex: 1;
  margin: 10px;
  text-align: center;
  padding: 20px;
}

.selectPunishingCharacter{
  flex: 1;
  margin: 10px;
  text-align: center;
  padding: 20px;
}

.selectMove{
  flex: 1;
  margin: 10px;
  text-align: center;
  padding: 20px;
}

.dropdown-input {
  border: none;          /* Removes the border */
  outline: none;         /* Removes the outline on focus */
  width: 50%;           /* Ensures the input takes full width */
  padding: 5px;          /* Optional: Add some padding */
  background: none;      /* Removes any background color */
  font: inherit;         /* Ensures the font is consistent */
  color: inherit;        /* Inherit color from the parent */
}


.button{
    display: flex;
    position: relative;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
}
.calcButton{
  background-color: #7d2082; 
  border: none;
  margin-top: 25px;
  padding: 15px 32px;
  text-align: center;
  color: #fff;
  text-decoration:solid;
  display: inline-block;
  z-index: 1;
  font-size: 24px;
  font-family: 'Koulan', sans-serif;
  cursor: pointer;

}

.content-container {
  position: absolute;
  bottom: 10px; /* Adjust this value to place it above the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Adjust the width as needed */
  display: flex;
  z-index: 2;
  flex-wrap: nowrap;
  height: 100%;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
}

.body .html{
  background-color: #1E1E1E;
}

.calcOutput{
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;
  display: flex;
  font-family: 'jetbrainsmono', sans-serif;
  height: auto;
  width: 85%;
}

.calcRectangle{
  border-radius: 15px;
  width: 100%;
}
.r{
  display: flex;
  border-radius: 15px;

  width: 100%;
  justify-content:  center;
  align-items: center;
}

.r .rec{
  width: 100%;
  border-radius: 25px;
  display: flex;
  height: auto;
  background: #D9D9D9;
}



.charinfo{
  height: fit-content;
  display: flex;
  width: 50vh;
  font-family: 'jetbrainsmono', sans-serif;

  flex-direction: column;
}

.outputinfo{
  padding: 10px;
  width: 70%;
  text-align: center;
  margin-top: 30px;
  background-color: white;
}

.outputheader{
  text-align: center;
  font-weight: bold;

}

.outputtext{
  padding: 10px;
}



.slideshow-container{
  align-items: center;
  display: flex;
  height:50vh;
  margin: 0 auto;
  max-width: 100vh;
  position: relative;
}

.slide{
  height: 50%;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: auto;
  max-width: 70%;
  z-index: 0;
  border-radius: 15px;
  /* Prevent distortion due to varying heights */
  object-fit: cover;
  background-size: contain;
}

.slide-center{
  box-shadow: -2px 5px 33x 6px rgba(0, 0, 0, 0.35);
  height: auto;
  max-height: 70%;
  max-width: 90%;
  width: auto;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  z-index: 2;
}

.slide-before{
  left: 30%;
  opacity: 50%;
  transform: translateX(-50%);
}

.slide-after{
  left:70%;
  opacity: 50%;
  transform: translateX(-50%)
}



.slideShow {
  position: relative;
  width: 100%;
  height: 100%;
}
.calcOutput{
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.moveinfo{
  padding: 2%;
  text-align: center;
  background-color: white;
}
.movesGifsInfo{
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 20px;
  position: relative;
  margin-top: 30px;
  height: fit-content; /* Full height of the viewport */
  flex-wrap:wrap;
  width: 100%;
  padding: 10px;
}

.sshow{
  position: relative;
  height: fit-content;
}




.aCharSlideShow, .pCharSlideShow {
  flex: 1; /* Ensure they take equal space */
  margin: 00px; /* Add spacing between them */
}


.arrow {
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex; 
  z-index: 2;
  align-items: center;
  justify-content: center;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}


.arrow svg {
  width: 24px;
  height: 24px;
}